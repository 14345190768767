document.addEventListener('DOMContentLoaded', () => {


    let lastScrollTop = pageYOffset;
    const link = document.getElementById('toggle-menu');
    const link2 = document.getElementById('toggle-menu2');
    const link3 = document.getElementById('toggle-menu3');
    const link4 = document.getElementById('toggle-menu4');
    const call = document.getElementById('call-link');
    //const call2 = document.getElementById('call-link2');
    const callSubmit = document.getElementById('call-submit');
    const painHaut2 = document.getElementById('painH2');
    const painHaut3 = document.getElementById('painH3');
    const painHaut4 = document.getElementById('painH4');
    const painBas2 = document.getElementById('painB2');
    const painBas3 = document.getElementById('painB3');
    const painBas4 = document.getElementById('painB4');
    const painHaut = document.getElementById('painH');
    const painBas = document.getElementById('painB');
    let hasScrolled = false;
    const titeLigne = document.getElementById('titeL');

    link.addEventListener('click', hamFunc);
    link2.addEventListener('click', hamFunc);
    link3.addEventListener('click', hamFunc);
    link4.addEventListener('click', hamFunc);
    call.addEventListener('click', openOverlay);







    // call2.addEventListener('click', () => {
    //     openOverlay();
    //     hamFunc();
    // });



    function openOverlay() {
        const requestOverlay = document.querySelector('.request-overlay');
        if (requestOverlay.classList.contains('hidden')) {
            requestOverlay.classList.remove('hidden');
        }
    }

    //ici c'est ce que l'on fait lors du submit
    callSubmit.addEventListener('click', () => {
        $('.request-overlay').addClass('hidden');
    });

    //variable pour le jquery
    let contMenuEnfant = $('.cont-menu-mot').children('span');
    //console.log(contMenuEnfant);



    let lesImages = document.querySelectorAll('.work-img');
    let lesImgIndex = 0;
    //console.log(lesImgIndex);
    setTimeout(()=>{
        $('.intro').css('display', 'none');
    },4000);








if(document.querySelector('.bandeau')){
    let minCarouAccueil = setInterval(() => {
        //console.log(lesImages[lesImgIndex]);
        //console.log(lesImgIndex);
        if(lesImgIndex>=8){
            lesImgIndex=0;
        }


        lesImages[lesImgIndex].classList.remove('.opa1');
        lesImages[lesImgIndex].classList.add('opa0');
        if (lesImgIndex >= 7){
            lesImgIndex=0;
        }else{
            lesImgIndex++;
            //console.log(lesImgIndex);
        }
        if(lesImages[lesImgIndex].classList.contains('opa0')){
            lesImages[lesImgIndex].classList.remove('opa0');
        }else

            lesImages[lesImgIndex].classList.add('.opa1');

    }, 800);
}


    function hamFunc() {
        //si les
        if (contMenuEnfant[0].classList.contains('bouge1') ||
            contMenuEnfant[1].classList.contains('bouge2')) {
            contMenuEnfant[0].classList.add('bouge-pu');
            contMenuEnfant[1].classList.add('bouge-pu');
            contMenuEnfant[0].classList.remove('bouge1');
            contMenuEnfant[1].classList.remove('bouge2');
        } else {
            contMenuEnfant[0].classList.add('bouge1');
            contMenuEnfant[1].classList.add('bouge2');
            contMenuEnfant[1].classList.remove('bouge-pu');
            contMenuEnfant[1].classList.remove('bouge-pu');
        }
        if (painHaut2.classList.contains('t-off1') ||
            painHaut.classList.contains('t-off1') ||
            painHaut3.classList.contains('t-off1') ||
            painHaut4.classList.contains('t-off1')) {
            window.addEventListener('scroll', (e) => {
                e.preventDefault();
            });

            painHaut2.classList.remove('t-off1');
            painHaut3.classList.remove('t-off1');
            painHaut4.classList.remove('t-off1');
            painHaut.classList.remove('t-off1');

            painHaut2.classList.add('t-on1');
            painHaut3.classList.add('t-on1');
            painHaut4.classList.add('t-on1');
            painHaut.classList.add('t-on1');
            $('.menu').removeClass('hidden');
        } else {
            window.addEventListener('scroll', (e) => {
                e.preventDefault();
            });

            painHaut2.classList.remove('t-on1');
            painHaut3.classList.remove('t-on1');
            painHaut4.classList.remove('t-on1');
            painHaut.classList.remove('t-on1');

            painHaut2.classList.add('t-off1');
            painHaut3.classList.add('t-off1');
            painHaut4.classList.add('t-off1');
            painHaut.classList.add('t-off1');
            $('.menu').addClass('hidden');
            $('.request-overlay').addClass('hidden');
        }

        if (painBas2.classList.contains('t-off2') ||
            painBas.classList.contains('t-off2') ||
            painBas3.classList.contains('t-off2') ||
            painBas4.classList.contains('t-off2')) {
            window.addEventListener('scroll', (e) => {
                e.preventDefault();
            });

            painBas2.classList.remove('t-off2');
            painBas3.classList.remove('t-off2');
            painBas4.classList.remove('t-off2');
            painBas.classList.remove('t-off2');

            painBas2.classList.add('t-on2');
            painBas3.classList.add('t-on2');
            painBas4.classList.add('t-on2');
            painBas.classList.add('t-on2');
        } else {
            window.addEventListener('scroll', (e) => {
                e.preventDefault();
            });
            painBas2.classList.remove('t-on2');
            painBas3.classList.remove('t-on2');
            painBas4.classList.remove('t-on2');
            painBas.classList.remove('t-on2');

            painBas2.classList.add('t-off2');
            painBas3.classList.add('t-off2');
            painBas4.classList.add('t-off2');
            painBas.classList.add('t-off2');
        }

    }

    document.addEventListener('scroll', () => {
        if (!hasScrolled) {
            //console.log('bnour');
            hasScrolled = true;
            if (titeLigne) {
                titeLigne.classList.add('tite-ligne');
            }

            // heroText.classList.add('hero-text');
        }
    });


// element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
    window.addEventListener("scroll", function () { // or window.addEventListener("scroll"....
        let st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (st > lastScrollTop) {
            // downscroll code
            //console.log('par en bas ');
            $('.menu-scroll').css({
                'top': '-200px'
            });
        } else {
            // upscroll code
            //console.log('par en haut ');

            $('.menu-scroll').css({
                'top': '0'
            });

        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }, false);
    console.log('screen: '+ screen.width);
   // document.getElementById('1').style.pointerEvents="none";


        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top
            }, 500);
        });




    if (document.querySelector('.hero-wrapper')) {
        const parallax = document.querySelector(".hero-wrapper");
        window.addEventListener("scroll", () => {
            console.log(window.pageYOffset);
            let offset = window.pageYOffset;
if(window.screen.width<=1280){
    console.log('<=1280');
    parallax.style.backgroundPositionY =offset*0.8 +"px";
            }else{
    parallax.style.backgroundPositionY = offset * 0.8 -100 + "px";
}


        });
    }


    //call to action du request a call
    document.getElementById('call-submit').onclick = ()=>{
        document.getElementById("r-call").submit();
    }

    if(document.getElementById("submit1")!==null){
        document.getElementById("submit1").onclick = ()=>{
            document.getElementById("form1").submit();
        }

        document.getElementById("submit2").onclick = ()=>{
            document.getElementById("form2").submit();
        }
    }





    if(document.querySelector('.services-hero')){
        console.log('bonour')
        let bgServices = $('.cont-hero-img-services');
        let lienServices1 = document.getElementById('rentals');
        let lienServices2 = document.getElementById('condo');
        let lienServices3 = document.getElementById('comm');
        let servSpan1 = document.getElementById('rentals-span');
        let servSpan2 = document.getElementById('condo-span');
        let servSpan3 = document.getElementById('comm-span');

        //console.log(lienServ);


        lienServices1.addEventListener('click', swapActive1);
        lienServices2.addEventListener('click', swapActive2);
        lienServices3.addEventListener('click', swapActive3);

        function swapActive1(){

                bgServices.css({
                    'background-image': 'url("./images/Gestion-Samurai_Services-BG-Rentals.svg")',
                    'background-size': 'cover',
                    'background-position': 'center'
                })

            lienServices1.style.opacity='1';
            servSpan1.classList.remove('hidden');
            servSpan2.classList.add('hidden');
            servSpan3.classList.add('hidden');
            lienServices2.style.opacity='0.5';
            lienServices3.style.opacity='0.5';


        }

        function swapActive2(){

                bgServices.css({
                    'background-image': 'url("./images/Gestion-Samurai_Services-BG-Condominium.svg")',
                    'background-size': 'cover',
                    'background-position': 'center'
                })
            lienServices2.style.opacity='1';
            servSpan2.classList.remove('hidden');
            servSpan1.classList.add('hidden');
            servSpan3.classList.add('hidden');
            lienServices1.style.opacity='0.5';
            lienServices3.style.opacity='0.5';

        }

        function swapActive3(){

            bgServices.css({
                'background-image': 'url("./images/Gestion-Samurai_Services-BG-Commercial.svg")',
                'background-size': 'cover',
                'background-position': 'center'
            })

            lienServices3.style.opacity='1';
            servSpan3.classList.remove('hidden');
            servSpan2.classList.add('hidden');
            servSpan1.classList.add('hidden');
            lienServices2.style.opacity='0.5';
            lienServices1.style.opacity='0.5';
        }


        function swapBgImg(){
//si le premier li est actif
            if(lienServices1.classList.contains('active-li')){
                bgServices.css({
                    'background-image': "url('../images/Gestion-Samurai_Services-BG-Rentals.jpg')"
                });
            }
            if(lienServices2){}
        }
    }
});


/*
OVERVIEW
A simple vertical carousel

Slides are cycled through by the two toggles on the right, previous and next.

Next
=> toggle clicked
=> active slide is faded out, moved up
=> next slide is faded in, moved up

Previous
=> toggle clicked
=> active slide is faded out, moved down
=> next slide is faded in, moved down
*/

$(document).ready(function () {

    // slide to start. should always be 1 as it's also the lower bound to the number of slides. corresponds to [pos] attribute on html element
    let active_slide = 1;

    // last slide. should be the upper bound to the number of slides. corresponds to [pos] attribute on html element
    let slide_count = 3;

    // speed of animations (ms)
    let speed = 100;


    // hide all slides that aren't starting active slide
    $(".slide[pos!='" + active_slide + "']").each(function () {
        $(this).hide();
    })


    $(".slide-toggle[direction='next']").click(function () {


        let next_slide = active_slide + 1;

        Next(active_slide, next_slide, slide_count, speed);

        // once animations are done, set new active slide
        if (next_slide <= slide_count) {
            active_slide = next_slide;
        } else {
            active_slide = 1;
        }


    })


    $(".slide-toggle[direction='prev']").click(function () {


        let next_slide = active_slide - 1;

        Prev(active_slide, next_slide, slide_count, speed);

        // once animations are done, set new active slide
        if (next_slide < 1) {
            active_slide = slide_count;
        } else {
            active_slide = next_slide;
        }


    })


})


function Next(active_slide, next_slide, slide_count, speed) {

// non active slides moved down so they can slide up when activated
    $(".slide[pos!='" + active_slide + "']").each(function () {
        $(this).css("top", "10px");
    })


    if (next_slide <= slide_count) {


        /*
        Note: delay only works if .hide() or .show() are in its internal queue. Therefore you need to pass an argument to it, even if it's 0. (praise be to stackoverflow)
        */

        $(".slide[pos='" + active_slide + "']").animate({
            opacity: 0,
            top: "-10px"
        }, {duration: speed}).hide(0).animate({top: "10px"});

        $(".slide[pos='" + next_slide + "']").delay(speed).show(0).animate({opacity: 1, top: "0px"}, {duration: speed});

        $(".index > p").text(next_slide + "/" + slide_count);


    } else {

        next_slide = 1;

        $(".slide[pos='" + active_slide + "']").animate({
            opacity: 0,
            top: "-10px"
        }, {duration: speed}).hide(0).animate({top: "10px"});

        $(".slide[pos='" + next_slide + "']").delay(speed).show(0).animate({opacity: 1, top: "0px"});

        $(".index > p").text(next_slide + "/" + slide_count);

    }

}

function Prev(active_slide, next_slide, slide_count, speed) {

    // non active slides moved up so they can slide down when activated
    $(".slide[pos!='" + active_slide + "']").each(function () {
        $(this).css("top", "-10px");
    })


    if (next_slide < 1) {

        next_slide = slide_count;

        $(".slide[pos='" + active_slide + "']").animate({
            opacity: 0,
            top: "10px"
        }, {duration: speed}).hide(0).animate({top: "10px"});

        $(".slide[pos='" + next_slide + "']").delay(speed).show(0).animate({opacity: 1, top: "0px"}, {duration: speed});

        $(".index > p").text(next_slide + "/" + slide_count);


    } else {


        $(".slide[pos='" + active_slide + "']").animate({
            opacity: 0,
            top: "10px"
        }, {duration: speed}).hide(0).animate({top: "10px"});

        $(".slide[pos='" + next_slide + "']").delay(speed).show(0).animate({opacity: 1, top: "0px"});

        $(".index > p").text(next_slide + "/" + slide_count);


    }

}



    console.log('test');










